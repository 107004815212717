<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: MODULE_PREFIX+'s' }">{{$t('label_'+MODULE_PREFIX+'s')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>

        <!--actions panel-->
        <b-card no-body v-if="$can('edit', MODULE_PREFIX+'.tab_details')">
            <b-card-body>
                <div class="text-uppercase mr-1 mb-1"><strong>{{moduleItem.name }}</strong>
                </div>
                <b-button variant="outline-primary"  class="mr-1" :to="{name:'consultant_edit', params:{id:moduleItem.id}}">
                    <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_edit')}}</span>
                </b-button>

            </b-card-body>
        </b-card>

        <b-card no-body>

            <b-card-body>
                <b-tabs content-class="mt-1" >
                    <b-tab
                            class="pt-2"
                            v-if="$can('view', MODULE_PREFIX+'.tab_details')"
                           @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"
                            lazy
                    >

                        <tab-details :edited-item="moduleItem" ></tab-details>

                    </b-tab>

                </b-tabs>
            </b-card-body>
        </b-card>


    </div>
</template>

<script>


    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton,

    } from 'bootstrap-vue'

    import tabDetails from './tabs/tabDetails'

    import {MODULE_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton,

            tabDetails,


        },
        data() {
            return {

                MODULE_PREFIX,

                is_deal: false,

                editedTask:false,
                editedNote:false,
                curPageLabel: 'label_informations',
                moduleItem: false,

            }
        },

        created() {

           let module_item_id = this.$router.currentRoute.params.id;


            this.fetchModuleItem(module_item_id);


        },
        mounted(){

            let alias_route_param = this.$router.currentRoute.params.alias;
            let self = this;
            if(alias_route_param){
                this.$nextTick(function(){self.$refs[alias_route_param+'_TAB'].activate();});
            }

        },

        methods: {

            fetchModuleItem(id){

                this.async('get', '/'+this.MODULE_PREFIX+'/' + id, {}, function (resp) {

                    this.moduleItem = resp.data;
                });

            },
            resolveActiveTab(){
                return false;
            }

        },


    }
</script>

