<template>
    <div>
        <b-row>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >

                <b-table-simple class="table-details" responsive no-border-collapse>
                    <b-tbody>
                        <b-tr>
                            <b-td width="40%">{{$t('label_phone')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.phone}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_username')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.name}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_first_name')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.firstName}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_last_name')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.lastName}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_email_address')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.emailAddress}}

                            </b-td>
                        </b-tr>



                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >
                <b-table-simple class="table-details" responsive  no-border-collapse>
                    <b-tbody>
                        <b-tr>
                            <b-td width="40%">{{$t('label_department')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.department? editedItem.department.name : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_position')}}</b-td>
                            <b-td class="actions">
                                {{editedItem.position}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_gmail')}}</b-td>
                            <b-td class="actions">
                                {{editedItem.gmailAddress}}
                            </b-td>
                        </b-tr>

                        <b-tr>
                            <b-td width="40%">{{$t('label_hourly_rate')}}</b-td>
                            <b-td>
                                {{editedItem.hourly_rate}}
                            </b-td>
                        </b-tr>


                    </b-tbody>
                </b-table-simple>



            </b-col>
        </b-row>

        <br>

    </div>
</template>

<script>
    import {
        BPopover,
        BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh

    } from 'bootstrap-vue'

    import { MODULE_PREFIX} from './../moduleHelper'
    export default {
        components: {
            BPopover,
            BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh,
 
        },

        props: ['editedItem'],
        data() {
            return {

                MODULE_PREFIX,

            }
        },


    }
</script>